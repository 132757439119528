/* eslint-disable max-len */
import { EmptyListPlaceholder } from 'components/empty-list-placeholder';
import { ClickNotificationDO, NotificationComponent } from 'components/notification-component';
import { Skeleton } from 'components/skeleton/skeleton';
import { Container, NotificationsContainer } from 'components/notifications-list-content/styles';
import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES, DEFAULT_NOTIFICATION_ICON, FONT_AWESOME_ICONS_PREFIX } from 'shared/consts';
import {
  selectNotifications,
  selectGetNotificationListStatus,
  selectPatchNotificationUuid, selectDeleteNotificationUuid,
} from 'store/notifications/selectors';
import { formatDistanceToNow } from 'date-fns';
import { useInfiniteScroll } from 'components/notifications-list-content/hooks/use-infinite-scroll.hook';
import { patchNotificationAndRedirect } from 'store/notifications/actions';
import { PATCH_NOTIFICATION_STATUS } from 'store/notifications/types';
import { getNotificationVariant } from 'components/notifications-list-content/utils/get-notification-variant';

export const NotificationsListContent = (): JSX.Element => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const notificationsStatus = useSelector(selectGetNotificationListStatus);
  const patchNotificationUuid = useSelector(selectPatchNotificationUuid);
  const deleteNotificationUuid = useSelector(selectDeleteNotificationUuid);
  const notificationsListContentContainerRef = useRef();
  const notificationsContainerRef = useRef();
  const totalDisplayedNotifications = notifications.length;
  const { isInfiniteScrolling } = useInfiniteScroll({
    notificationsListContentContainerRef,
    totalDisplayedNotifications,
  });

  const onClickHandler = useCallback(
    ({
      notificationUuid, notificationStatus, drawerClick,
    }: ClickNotificationDO) => {
      dispatch(
        patchNotificationAndRedirect.request({
          notificationUuid,
          inAppReadStatus:
            notificationStatus === PATCH_NOTIFICATION_STATUS.READ
              ? PATCH_NOTIFICATION_STATUS.READ
              : PATCH_NOTIFICATION_STATUS.UNREAD,
          drawerClick,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Container data-testid="notifications-list-content" ref={notificationsListContentContainerRef}>
      {notificationsStatus === ACTION_STATUSES.PENDING ? (
        <Skeleton />
      ) : (
        <>
          {notifications.length === 0 && <EmptyListPlaceholder />}
          <NotificationsContainer ref={notificationsContainerRef}>
            {notifications.map(
              ({
                uuid, date, in_app_read_at, description, title, icon, link, attachments, building_context, category_name,
              }) => (
                <NotificationComponent
                  parentRef={notificationsListContentContainerRef}
                  notificationUuid={uuid}
                  key={uuid}
                  icon={(icon || DEFAULT_NOTIFICATION_ICON).replace(FONT_AWESOME_ICONS_PREFIX, '')}
                  title={title}
                  message={description}
                  messageInfo={formatDistanceToNow(date, { addSuffix: true, includeSeconds: true })}
                  variant={getNotificationVariant({ in_app_read_at }, patchNotificationUuid, deleteNotificationUuid, uuid)}
                  onClick={onClickHandler}
                  withRedirectAction={!!(link || (attachments?.[0]?.attachment_uuid && !attachments?.[0]?.attachment_params))}
                  buildingUuid={building_context}
                  notificationCategory={category_name}
                  link={link}
                />
              ),
            )}
            {isInfiniteScrolling && <Skeleton />}
          </NotificationsContainer>
        </>
      )}
    </Container>
  );
};
